$ibx-aside-width :      260px;

.ibx-count{
    position: relative;
    padding: 0.3125rem 0.75rem;
    font-size: 0.75rem;
    color: $base_light;
    background: $white;
    border: 1px solid  $border-light;
    display: inline-block;
    font-weight: $fw-normal;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: $border-radius-sm;
    &:before,&:after{
        position: absolute;
        height: 0;
        width: 0;
        top: 50%;
        transform: translateY(-50%);
        content: '';
        border-style: solid;
    }
    &:before{
        right: -8px;
        border-color: transparent transparent transparent $border-light;
        border-width: 4px 0 4px 7px;
    }
    &:after{
        right: -6px;
        border-width: 3px 0 3px 6px;
        border-color: transparent transparent transparent $white;
    }
}

.nk-ibx-context{
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    &-group{
        display: flex;
        align-items: center;
    }
    &-badges{
        margin-right: 0.75rem;
    }
    &-text{
        display: inline;
        color: $base-light;
        .is-unread &{
            color: $base-text;
        }
        .heading{
            color: $base-color;
            .is-unread &{
                font-weight: $fw-medium;
            }
        }
    }
}

.ibx-actions{
    position: relative;
    display: inline-block;
    transition: background-color .3s;
    &-hidden{
        position: absolute;
        display: flex;
        right: calc(100% + 8px);
        opacity: 0;
        transition: background-color .3s;
        .nk-ibx-item:hover &{
            opacity: 1;
            background-color:$lighter;
        }
    }
    .nk-ibx-item:hover &{
        background-color:$lighter;
    }
}

.nk-ibx{
    position: relative;
    display: flex;
    overflow: hidden;
    min-height: calc(100vh - (#{$header-height} + 64px));
    max-height: calc(100vh - (#{$header-height} + 64px));
    background: $white;
    &-aside{
        position: absolute;
        background: $white;
        width: $ibx-aside-width;
        overflow: hidden;
        height: 100%;
        max-height: 100%;
        display: flex;
        flex-direction: column;
        align-items: stretch;
        flex-shrink: 0;
        border-right: 1px solid $border-light;
        transform: translateX(-100%);
        z-index: 99;
        transition: transform .4s linear;
        + .toggle-overlay{
            z-index: 90;
            position: absolute;
        }
        &.content-active{
            transform: none;
        }
    }
    &-nav{
        overflow: auto;
        flex-grow: 1;
        height: 100%;
        max-width: 100%;
        .simplebar-content{
            height: 100%;
            display: flex;
            flex-direction: column;
        }
        &-head{
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 2rem 1.5rem .75rem;
            .title{
                font-size: 1rem;
                margin-bottom: 0;
            }
            .dropdown > a, > .link{
                color: $base-light;
                font-size: 0.875rem;
            } 
        }
    }
    &-head{
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: .75rem 1.25rem;
        border-bottom: 1px solid $border-light;
        min-height: 61px;
        .title{
            font-size: 1rem;
            margin-bottom: 0;
        }
        &-actions, &-tools {
            display: flex;
            align-items: center;
        }
        &-tools {
            .btn-icon.btn-trigger .icon {
                font-size: 1.25rem;
            }
        }
        .search{
            &-wrap{
                padding: 0 1.365rem 0 1rem;
                .icon{
                    font-size: 1.25rem;
                }
            }
            &-back{
                transform: translate(-0.5rem, -50%);
            }
        }
        &-check{
            display: flex;
            margin-right: .325rem;
        }

    }
    &-action{
        position: relative;
        padding: 1rem 1.5rem;
        border-bottom: 1px solid $border-light;
        &-list{
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
        &-item{
            a{
                display: flex;
                align-items: center;
            }
            .icon{
                font-size: 1.5rem;
                + span{
                    margin-left: 0.5rem;
                }
            }
            .search-toggle{
                .icon{
                    font-size: 1.125rem;
                }
            }
        }
        
    }
    &-menu{
        li{
            margin: 2px 0;
            &:hover{
                background: $lighter;
                border-radius: $border-radius-sm;
            }
            &.active{
                background: $accent-100;
                border-radius: $border-radius-sm;
                
            }
        }
        &-item{
            position: relative;
            display: flex;
            align-items: center;
            padding: .5rem .75rem;
            border-radius: $border-radius;
            .icon{
                font-size: 1.25rem;
                line-height: 1.5rem;
                width: 2rem;
                color: $base-light;
            }
            .badge{
                margin-left: auto;
                min-width: 2.25rem;
                justify-content: center;
            }
            .active &{
                .icon{
                    color: $accent-color;
                }
            }
        }
        &-text{
            font-size: .875rem;
            color: $base-text;
            font-weight: $fw-medium;
            line-height: 1.5rem;
            .active &{
                color: $accent-color;
            }
        }
    }
    &-label, &-contact {
        > li {
            position: relative;
            display: flex;
            align-items: center;
            border-radius: $border-radius;
            margin: 2px 0;
            transition: background-color .3s;
            > a{
                display: flex;
                padding: .5rem .75rem;
                flex-grow: 1;
            }
            .dropdown{
                opacity: 0;
                > a{
                    color: $base-text;
                    padding: .6875rem;
                }
            }
            &:hover{
                background: $lighter;
                .dropdown{ 
                    opacity: 1;
                }
            }
            &.active{
                background: $accent-light;
            }
        }
    }
    &-label{
        &-text{
            line-height: 1;
            margin-left: 1rem;
            font-size: .875rem;
            color: $base-text;
            font-weight: $fw-medium;
        }
    }
    &-menu,&-label,&-contact{
        padding-left: .75rem;
        padding-right: .75rem;
    }
    &-menu{
        padding-top: .75rem;
    }
    &-contact{
        padding-bottom: 1.75rem;
    }
    &-status{
        margin-top: auto;
        background: $lighter;
        padding: 1.5rem 1.5rem;
        &-info{
            margin-bottom: 0.5rem;
            .icon{
                width: 1.25rem;
                display: inline-block;
            }
        }
    }
    &-body{
        position: relative;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        flex-grow: 1;
        flex-shrink: 1;
    }
    &-list{
        display: flex;
        flex-direction: column;
        max-height: 100%;
        height: 100%;
        overflow: auto;
    }
    &-item{
        position: relative;
        display: flex;
        align-items: center;
        background: $white;
        padding: 1rem 1.25rem;
        transition: background-color .3s;
        cursor: pointer;
        &:not(:last-child){
            border-bottom: 1px solid $border-light;
        }
        &:not(.no-hover):hover,&.active {
            background: $lighter;
        }
        &.is-unread {
            background: rgba($lighter, .6);
        }
        &-elem{
            padding: 0 8px;
            flex-shrink: 0;
            &:first-child{
                padding-left: 0;
            }
        }
        &-check{
            width: 28px;
            display: inline-flex;
        }
        &-fluid{
            flex-grow: 1;
            flex-shrink: 1;
            width: 100%;
            max-width: 100%;
            overflow: hidden;
        }
        &-user{
            .user-name{
                max-width: calc(100% - 50px);
                .lead-text{
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    font-weight: $fw-normal;
                    .is-unread &{
                        font-weight: $fw-medium;
                    }
                }
            }
        }
        &-count{
            width: 3.25rem;
        }
        &-time{
            width: 80px;
            text-align: right;
        }
        &-attach{
            width: 2.25rem;
            text-align: center;
        }
        &-star{
            .asterisk{
                padding: 0 .25rem;
                a{
                    display: flex;
                }
                .icon{
                    line-height: 1;
                    font-size: 1.125rem;
                }
            }
        }
        &-tools{
            padding-right: 0;
            margin-left: auto;
            text-align: right;
        }
        &-check,&-star,&-attach{
            position: relative;
            z-index: 2;
        }
        &-tools{
            position: relative;
        }
    }
    &-link{
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: 1;
        + .nk-ibx-item-elem{
            padding-left: 0;
        }
    }
    &-view{
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: $white;
        z-index: 2;
        opacity: 0;
        visibility: hidden;
        pointer-events: none;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        &.show-ibx{
            opacity: 1;
            visibility: visible;
            pointer-events: all;
        }
    }
    &-reply{
        &-head{
            padding: 1.5rem 1.25rem 0;
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            .title{
                margin-bottom: .75rem;
            }
        }
        &-header{
            margin-right: -.5rem;
        }
        &-item{
            position: relative;
            > div:not(.nk-reply-header){
                margin-left: 0;
                margin-top: 1.25rem;
                margin-bottom: 0.75rem;
            }
            &:not(:last-child){
                &:after{
                    position: absolute;
                    left: 1.25rem;
                    right: 1.25rem;
                    height: 1px;
                    bottom: 1rem;
                    content: '';
                    background: $border-light;
                }
            }
        }
    }
}

@include media-breakpoint-up(sm){
    .nk-ibx{
        &-item,&-head{
            padding-left: 2.25rem;
            padding-right: 2.25rem;
        }
        &-head{
            .search-wrap{
                padding: 0 2.375rem 0 2rem;
            }
        }
        &-reply{
            &-head{
                padding: 2em 2.25rem 0;
            }
            &-item{
                padding-bottom: 2.75rem;
                > div:not(.nk-reply-header){
                    margin-left: 56px;
                }
                &:not(:last-child):after {
                    bottom: 1.375rem;
                    left: 2.25rem;
                    right: 2.25rem;
                }
            }
            &-quick-btns, &-form {
                margin-left: 5.75rem;
            }
        }
    }
}
@include media-breakpoint-up(md){
    .nk-ibx{
        &-item{
            &-user{
                width: 180px;
            }
        }
    }
}
@include media-breakpoint-up(lg){
    .nk-ibx{
        &-aside{
            position: static;
            transform: none;
            transition: none;
            height: calc(100vh - 128px);
        }
        &-menu,&-label,&-contact{
            padding-left: 1rem;
            padding-right: 1rem;
        }
        &-menu{
            padding-top: 1rem;
        }
        &-contact{
            padding-bottom: 1rem;
        }
        &-nav{
            &-head{
                padding-left: 1.75rem;
                padding-right: 1.75rem;
            }
        }
        &-item,&-head{
            padding-left: 1.75rem;
            padding-right: 1.75rem;
        }
        &-head{
            .search-wrap{
                padding: 0 2.25rem 0 1.5rem;
            }
        }
        &-body{
            max-width: calc(100% - #{$ibx-aside-width});
        }
        &-reply {
            &-quick-btns, &-form {
                margin-left: 5.25rem;
            }
            &-item,&-head{
                padding-left: 1.75rem;
                padding-right: 1.75rem;
            }
        }
    }
}

@include media-breakpoint-up(xl){
    .nk-ibx{
        &-aside{
            width: 300px;
        }
        &-item{
            &-user{
                width: 200px;
            }
            &-time{
                margin-right: 5rem;
            }
        }
    }
}
@include media-breakpoint-up(xxl){
    .nk-ibx{
        &-item{
            &-user{
                width: 260px;
            }
            &-tools{
                margin-left: 5rem;
            }
        }
    }
}

@include media-breakpoint-down(lg){
    .nk-ibx{
        &-item{
            &-attach{
                display: none;
            }
        }
    }
}

@include media-breakpoint-down(sm){
    .nk-ibx{
        &-item{
            flex-wrap: wrap;
            &-elem{
                padding: 0 4px;
            }
            &-fluid{
                padding-left: 32px;
                padding-right: 48px;
                margin-top: 6px;
                width: calc(100% - 2.5rem);
                .heading {
                    display: block;
                }
            }
            &-count,&-attach{
                display: none;
            }
            &-user{
                flex-grow: 1;
                .user-avatar{
                    height: 28px;
                    width: 28px;
                    font-size: $fx-sz-12;
                }
            }
            &-time{
                position: absolute;
                right: 32px;
                top: 19px;
            }
            &-tools{
                position: absolute;
                right: 28px;
                text-align: right;
                bottom: 18px;
            }
            &-star{
                position: absolute;
                left: 28px;
                top: 50px;
            }
        }
        &-context-badges{
            display: none;
        }
    }
    .ibx-actions-hidden{
        display: none;
    }
}

@include media-breakpoint-down(mb){
    .nk-ibx{
        &-item{
            &-star{
                left: 12px;
            }
            &-time{
                right: 12px;
            }
            &-tools{
                right: 8px;
            }
        }
        &-reply{
            &-head{
                .title{
                    font-size: 1.125rem;
                }
            }
            .btn-icon.btn-trigger{
                padding-top: 0.3125rem;
                padding-bottom: 0.3125rem;
                .icon{
                    width: 1.875rem;;
                }
            }
        }
    }
}
@media (max-width:359px){
    .nk-ibx{
        &-head{
            .btn-icon.btn-trigger{
                padding-top: 0.3125rem;
                padding-bottom: 0.3125rem;
                .icon{
                    width: 1.875rem;
                }
            }
        }
    }
}

@include media-breakpoint-between(sm,md){
    .nk-ibx{
        &-aside {
            width: 320px;
        }
        &-menu, &-label, &-contact {
           padding-left: 1.5rem; 
           padding-right: 1.5rem; 
        }
        &-nav-head, &-status {
            padding-left: 2.25rem;
            padding-right: 2.25rem;
        }
    }
}
